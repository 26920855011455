/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
div.sub1 {
  float: right;
  margin: 84px 3.5% 27px 0;
}
.sub1 > .item {
  margin: 0 30px;
}
.sub1 > .item.exit {
  margin-right: 0;
}
.sub1 > .item.init {
  margin-left: 0;
}
div.sub1 .menu {
  font-family: 'Alegreya', serif;
  letter-spacing: 0.03em;
}
div.sub1 a.path {
  color: #c35532;
}
/* LEVEL 2 */
/* LEVEL 3 */
#cmsbox {
  float: right;
  margin: 19px 35px 65px 0;
}
#cmsbox .meta {
  float: left;
  padding: 0 8px 0 9px;
  font-size: 14px;
  letter-spacing: 0.05em;
  border-left: 1px solid #843249;
}
#cmsbox .meta.service_mo {
  border: none;
  padding-left: 0;
}
#cmsbox .meta.auth {
  padding-right: 0;
}
/******* layout-large.less 2013-1-16 *******/
#multimood {
  margin-top: 140px;
}
#root div.pict {
  margin: 0;
  width: 100%;
}
#root div.pict.tiny {
  width: 50%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 3.5%;
  margin-left: 3.5%;
}
.area .part,
.area > .grid table {
  margin-right: 3.5%;
  margin-left: 3.5%;
  width: 93%;
}
.area .tiny {
  width: 43%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 7%;
  margin-left: 7%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 86%;
}
.area > .slim .tiny {
  width: 36%;
}
.north {
  width: 100%;
}
.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north h2,
.north .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.north .part,
.north > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north > .slim .part,
.north > .slim.grid table {
  width: 100%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */